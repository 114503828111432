import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Photolithography`}</h4>
    <p>{`In photolithography, light is used to draw patterns into semiconductor wafers, creating the tiny circuits that comprise logic chips. A photolithography tool passes light through a photomask-a transparent plate with a circuit pattern-to transfer that pattern to a wafer coated with photoresist chemical. (Photomasks are themselves made with lithography tools.) The light dissolves parts of the photoresist according to the circuit pattern.`}</p>
    <p>{`Advanced photolithography is critical to mass production of cutting-edge semiconductors. It requires enormously complex, expensive equipment supplied by only a few vendors in Europe and Japan. The most sophisticated photolithography processes involve extreme ultraviolet (EUV) scanners, produced exclusively by the Dutch firm ASML.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      